
import { mapGetters, mapState } from 'vuex'
import cms from '~/mixins/common/cms'
export default {
    name: 'Theme3PopupComponent',
    mixins: [cms],
    computed: {
        ...mapGetters({
            getCMS: 'cms/getCMS',
            splashScreen: 'cms/splashScreen',
            maintenance: 'themes/settings/maintenance',
        }),
        ...mapState({
            dialog: (state) => state.settings.displayPopup,
            loading: (state) => state.settings.changeLanguageLoading,
        }),
        isImageExist() {
            let isExist = false
            if (!this.splashScreen?.items) {
                isExist = false

                return false
            }

            this.splashScreen?.items.forEach((item) => {
                const getImage = this.cmsImageLink({
                    path:
                        (item?.image_path &&
                            item?.image_path[this.handleCookie('wl_locale')]) ||
                        '',
                    code: this.splashScreen.component_code,
                })
                if (getImage) isExist = true
            })
            return isExist
        },
    },
    mounted() {
        // Check if the cookie exists; if it does, hide the banner
        if (this.$cookie.get('hidePopup')) {
            this.$store.commit('settings/setDisplayPopup', false)
        } else {
            this.$store.commit('settings/setDisplayPopup', true)
        }
    },
    methods: {
        closePopup() {
            this.$store.commit('settings/setDisplayPopup', false)
            // Kevin told me to remove the 1 hour timer
            this.$cookie.set('hidePopup', true, {
                path: '/',
                maxAge: 15 * 60,
            })
        },
    },
}
